<template>
  <div>
    <SchoolLeaveEntitlementTable
      :items="items"
      :is-busy="isBusyStore"
      :pagination="pagination"
      @navigateToIndividual="goToStaffEntitlement($event.item)"
      @row-clicked="goToStaffEntitlement"
    >
    </SchoolLeaveEntitlementTable>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SchoolLeaveEntitlementTable from "@/modules/school/components/management/leave/entitlement/SchoolLeaveEntitlementTable";

export default {
  name: "StaffLeaveEntitlement",
  components: { SchoolLeaveEntitlementTable },
  data() {
    return {
      isBusy: false,
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    /**
     * Fetch Staff list from store
     */
    fetch() {
      this.isBusy = true;
      this.$store
        .dispatch("fetchStaffList")
        .finally(() => (this.isBusy = false));
    },

    /**
     * Proceed to Staff Individual Entitlement Page
     *
     * @param data
     */
    goToStaffEntitlement(data) {
      console.log(data);
      this.$router.push({
        name: "staffEntitlementPage",
        query: { id: data.id, name: data.full_name },
      });
    },
  },
  computed: {
    ...mapGetters({
      items: "getStaffList",
      pagination: "getStaffListPagination",
      isBusyStore: "getStaffListBusy",
    }),
  },
};
</script>

<style scoped></style>
